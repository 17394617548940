import React, { useState } from "react";
import { Link } from "gatsby";
import * as yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../components/contact/ContactCard";
import CardHeader from "../components/contact/ContactCardHeader";
import CardBody from "../components/contact/ContactCardBody";
import CardFooter from "../components/contact/ContactCardFooter";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FiMail } from "@react-icons/all-files/fi/FiMail";
import { BsPerson } from "@react-icons/all-files/bs/BsPerson";
import WindowSize from "../utilities/WindowSize";
//styles
import contactStyle from "../components/contact/contact.module.css";

const serverEndpoint = "https://quasiscience.com/charity-contacts/";

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name must have at least 3 characters.")
    .max(50, "Name cannot be longer than 50 characters.")
    .required("Name is required."),
  email: yup
    .string()
    .email("Please provide a valid email address.")
    .required("Email is required."),
  message: yup
    .string()
    .min(50, "Name must have at least 50 characters.")
    .max(800, "Name cannot be longer than 800 characters.")
    .required("Message cannot be empty."),
  terms: yup.bool().required().oneOf([true], "This box needs to be ticked!"),
});

const Contact = () => {
  let window = WindowSize();

  let [buttonText, setButtonText] = useState("Send");

  return (
    <Layout>
      <Metadata
        title="Contact Us"
        description="We encourage you to get in touch if you have any questions or to share your feedback."
      />
      <div className={`sectionMain ${contactStyle.verticalPosition}`}>
        <Row className={"no-margin"}>
          <Col className={contactStyle.centerCol} xs={12} md={9} lg={7}>
            <Card className={`${contactStyle.card}`}>
              <Formik
                validationSchema={schema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  setButtonText("... Sending ...");
                  let parcel = {
                    ...values,
                    website: "https://hopelabs.co.uk",
                  };
                  axios({
                    method: "post",
                    url: serverEndpoint,
                    data: parcel,
                    headers: { "Content-Type": "application/json" },
                  })
                    .then(response => {
                      if (response.status === 200) {
                        setButtonText("Message Sent");
                        setTimeout(function () {
                          setButtonText("Send");
                        }, 1000);
                        // alert("Message successfully delivered.")
                        resetForm();
                        console.log(response);
                      } else if (response.status !== 200) {
                        alert("Message failed to deliver.");
                        console.log(response);
                      }
                    })
                    .catch(error => {
                      alert("Error occurred.");
                      console.error("There was an error!", error);
                    });
                }}
                // onBlur={(event) => {handleBlur(event)}}
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                  terms: false,
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form
                    noValidate
                    className={contactStyle.form}
                    method={"POST"}
                    action={serverEndpoint}
                    onSubmit={handleSubmit}
                  >
                    <CardHeader className={contactStyle.cardHeader}>
                      <h1 style={{ fontWeight: "500" }}>Contact us</h1>
                    </CardHeader>
                    <h5 className={contactStyle.annotation}>
                      Thank you for your interest in our venture.
                    </h5>
                    <h5 className={contactStyle.annotation}>
                      We would like to hear from you!
                    </h5>
                    <CardBody>
                      <Form.Row>
                        <Col>
                          <InputGroup className={contactStyle.margin}>
                            {window.width >= 400 ? (
                              <div
                                className={`${contactStyle.iconSquare} d-none d-sm-block`}
                              >
                                <InputGroup.Append>
                                  <BsPerson />
                                </InputGroup.Append>
                              </div>
                            ) : null}
                            <Form.Control
                              placeholder="Name..."
                              name="name"
                              type="text"
                              aria-label="Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                touched.name && errors.name
                                  ? "is-invalid"
                                  : null
                              }
                              value={values.name}
                            />
                          </InputGroup>
                          {touched.name && errors.name ? (
                            <div className={contactStyle.errorBox}>
                              <p className={contactStyle.errorMessage}>
                                {errors.name}
                              </p>
                            </div>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <InputGroup className={contactStyle.margin}>
                            {window.width >= 400 ? (
                              <div
                                className={`${contactStyle.iconSquare} d-none d-sm-block`}
                              >
                                <InputGroup.Append>
                                  <FiMail />
                                </InputGroup.Append>
                              </div>
                            ) : null}
                            <Form.Control
                              placeholder="Email..."
                              name="email"
                              type="email"
                              aria-label="Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                touched.email && errors.email
                                  ? "is-invalid"
                                  : null
                              }
                              value={values.email}
                            />
                          </InputGroup>
                          {touched.email && errors.email ? (
                            <div className={contactStyle.errorBox}>
                              <p className={contactStyle.errorMessage}>
                                {errors.email}
                              </p>
                            </div>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <InputGroup className={contactStyle.margin}>
                            <Form.Control
                              placeholder="Message..."
                              name="message"
                              type="text"
                              as="textarea"
                              rows="5"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                touched.message && errors.message
                                  ? "is-invalid"
                                  : null
                              }
                              value={values.message}
                            />
                          </InputGroup>
                          {touched.message && errors.message ? (
                            <div className={contactStyle.errorBox}>
                              <p className={contactStyle.errorMessage}>
                                {errors.message}
                              </p>
                            </div>
                          ) : null}
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col>
                          <InputGroup className={contactStyle.margin}>
                            <Form.Check
                              label="I agree with Hope Labs'"
                              name="terms"
                              id="formHorizontalRadios3"
                              style={{ display: "inline-block" }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                touched.terms && errors.terms
                                  ? "is-invalid"
                                  : null
                              }
                              value={values.terms}
                            />
                            <Link
                              to={"/privacy"}
                              style={{ marginLeft: "0.25rem" }}
                            >
                              {"Privacy Policy"}
                            </Link>
                            .
                          </InputGroup>
                          {touched.terms && errors.terms ? (
                            <div className={contactStyle.errorBox}>
                              <p className={contactStyle.errorMessage}>
                                {errors.terms}
                              </p>
                            </div>
                          ) : null}
                        </Col>
                      </Form.Row>
                    </CardBody>
                    <CardFooter className={contactStyle.cardFooter}>
                      <Button
                        className={contactStyle.contactButton}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {buttonText}
                      </Button>
                    </CardFooter>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Contact;
