import React from "react";
import PropTypes from "prop-types";
import contactStyle from "./contact.module.css";

export default function CardHeader(props) {
  const { children, ...rest } = props;
  return (
    <div className={contactStyle.cardHeader} {...rest}>
      {children}
    </div>
  );
}

CardHeader.propTypes = {
  children: PropTypes.node,
};
