import React from "react";
import PropTypes from "prop-types";
import contactStyle from "./contact.module.css";

export default function CardBody(props) {
  const { children, ...rest } = props;
  return (
    <div className={contactStyle.cardBody} {...rest}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  children: PropTypes.node,
};
